import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";


function App() {
    return (
        <div>
            <Router>
                <Routes>
                    <Route path="/deal-enrichment-adapter-frm" element={<Home />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;