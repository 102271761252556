import {Form} from "@formio/react";
import { Container, Col, Row } from "react-bootstrap";
import formConfig from '../resources/deal-enrichment-no-api.json';
import '../styles/form-renderer.css';
import '../styles/standard.css'


function Home() {
    return (
        <Container>
            <Row>
                <Col>
                    <h2 className="page-headers">Deal Enrichment Adapter Form</h2>
                    <div className="main">
                        <Form form={formConfig}/>
                    </div>
                </Col>
            </Row>
        </Container>

    )
}

export default Home;