// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-headers {
    display: flex;
    justify-content: center;
    padding-top: 1%;
}

.main {
    padding-top: 1%;
    padding-bottom: 10%;
}

`, "",{"version":3,"sources":["webpack://./src/styles/form-renderer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB","sourcesContent":[".page-headers {\n    display: flex;\n    justify-content: center;\n    padding-top: 1%;\n}\n\n.main {\n    padding-top: 1%;\n    padding-bottom: 10%;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
